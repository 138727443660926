import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Col, Row, Form } from 'react-bootstrap';
import get from 'lodash/get'
import InnerBanner from "../components/inner-banner"
import InnerDetail from "../components/inner-detail"
import OtherNews from "../components/othernewsSection"
import { useQuery } from '@apollo/client';
import ReactMarkdown from "react-markdown/with-html"
import Modal from 'react-bootstrap/Modal'
import DownloadReportForm from "../components/forms/download-report"
import $ from "jquery"
import gql from "graphql-tag"
import SEO from "../components/Seo/seo";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { Logo } from '../components/icon';

import CountUp from 'react-countup';
const SINGLE_NEWS = gql`
  query GetNews ($URL: String!){	
	  newsEvents  (where:{URL: $URL}){
        Meta_Description
        Meta_Title
        Title
        URL
        id
        News_Date
        Download_Report_Email
		Banner_Image {
	      url
	    }
		Intro_Image {
	      url
	    }
      imagetransforms
      Content
		Above_Intro_Content
	    Below_Intro_Content
	    Video_Background_Image {
	      url
	    }
	    News_Video_Embed_URL
      }
  }
`;

function NewsDetail(props) {
  useEffect(() => {
    if ($(".reportformdownload").length > 0) {
      $(".reportformdownload").hide();
      $(".triggerreportform").insertAfter('.reportformdownload');
    } else {
      $(".triggerreportform").hide();
    }
  })

  const [show, setShow] = useState(false);
  const News = get(this, 'props.data.GetNews')
  const OurvideosList = props.allStrapiOurVideos
  const { loading: singlenewsloading, error: singlenewserror, data: singlenews } = useQuery(SINGLE_NEWS, {
    variables: { URL: props.detailpage }
  });
  const location = useLocation();
  const thePath = location.pathname
  const blogurl = process.env.GATSBY_SITE_URL + thePath;

  useEffect(() => {
    if (singlenews && singlenews.newsEvents.length > 0) {
      const item = singlenews.newsEvents[0];

      // Adding Article Schema
      const articleSchema = {
        "@context": "http://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": blogurl
        },
        "headline": item.Title,
        "image": {
          "@type": "ImageObject",
          "url": item.Banner_Image?.url
        },
        "datePublished": item.News_Date,
        "dateModified": item.News_Date,
        "author": {
          "@type": "Person",
          "name": "Jack Reid"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Orlando Reid",
          "logo": {
            "@type": "ImageObject",
            "url": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/Orlando_reid_logo_d10cc8f5cf.jpg"
          }
        },
        "description": item.Meta_Description
      };

      // Adding Speakable Schema
      const speakableSchema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": item.Title,
        "url": blogurl,
        "speakable": {
          "@type": "SpeakableSpecification",
          "xpath": [
            "/html/head/title",
            "/html/head/meta[@name='description']/@content"
          ]
        }
      };

      // Injecting the script for both schemas
      const articleScript = document.createElement('script');
      articleScript.type = 'application/ld+json';
      articleScript.innerHTML = JSON.stringify(articleSchema);

      const speakableScript = document.createElement('script');
      speakableScript.type = 'application/ld+json';
      speakableScript.innerHTML = JSON.stringify(speakableSchema);

      document.head.appendChild(articleScript);
      document.head.appendChild(speakableScript);

      return () => {
        document.head.removeChild(articleScript);
        document.head.removeChild(speakableScript);
      };
    }
  }, [singlenews, blogurl]);


  if (singlenewsloading) return (
    <div className="static-details gql-loading-screen">
      <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
      </Container>
    </div>);

  return (
    <>

      {singlenews.newsEvents.map((item, index) => {        
        return <>
          <SEO title={item.Meta_Title} description={item.Meta_Description} />
          {/* <Helmet>
            <script type="application/ld+json">{`{
  "@context": "http://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
  "@type": "WebPage",
  "@id": "https://www.orlandoreid.co.uk${thePath}"
  },
  "headline": "${item.Title}",
  "image":{
  "@type":"ImageObject",
  "url":"${item.Banner_Image?.url}"
  },
  "datePublished": "${item.News_Date}",
  "dateModified": "${item.News_Date}",
  "author": {
  "@type": "Person",
  "name": "Jack Reid"
  },
  "publisher": {
  "@type": "Organization",
  "name": "Orlando Reid",
  "logo": {
  "@type": "ImageObject",
  "url": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/Orlando_reid_logo_d10cc8f5cf.jpg"
  }
  },
  "description": "${item.Meta_Description}"
  
 }`}</script>
  </Helmet> */}
  <Helmet>
  <title>{item?.Title}</title>
  <meta name="description" content={item?.Meta_Description} />
        {/* <script type="application/ld+json">{`{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${item?.Title}",
              "speakable": {
                "@type": "SpeakableSpecification",
                "xpath": [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
              },
         "url": "${blogurl}"         
        }`}</script> */}
         
        </Helmet>
         

          <InnerBanner imagetransforms={item.imagetransforms} videourl={item.News_Video_Embed_URL} alias="stories" Title={item.Title} NewsDate={item.News_Date} BannerImg={item.Banner_Image} id={item.id} />
          <InnerDetail Content={item.Content} AboveContent={item.Above_Intro_Content} BelowContent={item.Below_Intro_Content} videobg={item.Video_Background_Image} videourl={item.News_Video_Embed_URL} PageName={"News Events"} />
          <a href="javascript:;" className="btn btn-success btn triggerreportform" onClick={() => setShow(true)}>Download the full report</a>
          <OtherNews videodata={props.videodata} caturl={props.caturl} CurrentNews={item.Title} CurrentID={props.CategoryId} OurvideosList={OurvideosList} newsvideos />
          <Modal
            show={show}
            centered={true}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w modal-form download-form"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                <p>Fill up the form to download the full London Market Update Report.</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DownloadReportForm to_email_id={item.Download_Report_Email} newstitle={item.Title} formName={`Download Report Form`} />
            </Modal.Body>
          </Modal>
        </>
      })}
    </>
  )
}
export default NewsDetail

